import React, { useState } from "react"
import Modal from "react-modal"
import CloseIcon from "../CloseIcon"

type Props = {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  className?: string
  ariaLabel: string
}
const Dialog = ({ isOpen, onClose, children, ariaLabel, className }: Props) => {
  return (
    <div>
      <Modal
        bodyOpenClassName="no-v-scroll"
        overlayClassName={`jt-modal ${className}`}
        className="jt-modal__content"
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={ariaLabel}>
        <CloseIcon onClick={onClose} />
        {children}
      </Modal>
    </div>
  )
}

export default Dialog
