import React from "react"

const CloseIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="c-menu" onClick={onClick}>
      <span className="open"></span>
      <span className="open"></span>
    </div>
  )
}

export default CloseIcon
